import React, { Component } from 'react';
import firebase from '../util/Firebase';
import GoogleMap from "./GoogleMap";
import Loading from "../nav/Loading";

class Map extends Component {

    constructor(props) {
        super(props);

        this.state = {
            places: [],
            placesMap: {},
            user: {},
            loggedIn: -1
        };

        this.placesRef = firebase.firestore().collection('places').limit(1000);
        this.usersRef = firebase.firestore().collection('users');
    }

    componentDidMount() {
        this.placesUnsub = this.placesRef.orderBy("createdTimestamp", "desc").onSnapshot(this.onPlacesUpdate);
        firebase.auth().onAuthStateChanged((user) => {
            if(user) {
                this.usersRef.doc(user.uid).get().then((userDoc) => {
                    if (userDoc.exists) {
                        this.setState({user: userDoc.data()});
                    }
                });
                this.setState({loggedIn: 1});
            } else {
                this.setState({loggedIn: 0});
            }
        });
    }

    componentWillUnmount() {
        this.placesUnsub = null;
    }

    onPlacesUpdate = (querySnapshot) => {

        const places = [];
        const placesMap = {};

        querySnapshot.forEach((doc) => {
            const { createdTimestamp, description, lat, lon, name, vicinity, country, updatedTimestamp } = doc.data();
            const dataToStore = { key: doc.id, createdTimestamp, description, lat, lon, name, vicinity, country, updatedTimestamp };

            if(!placesMap[name]) {
                places.push(dataToStore);
            }
            placesMap[name] = dataToStore;
        });

        this.setState({
            places,
            placesMap
        });
    }

    onBoundsChange = (center, zoom /* , bounds, marginBounds */) => {

        const lat = center[0];
        const lng = center[1];

        // console.log(this.state.user.uid);
        // console.log(zoom);
        // console.log(lat);
        // console.log(lng);

        if(this.state.user.uid) {
            this.usersRef.doc(this.state.user.uid).update({
                mapUserZoom: zoom,
                mapUserCentreLat: lat,
                mapUserCentreLng: lng
            });
        }
    };

    render() {
        return (
            <div className="panel panel-default app-min-height">
                <div style={{height: '75vh', width: '100%'}}>

                    {this.state.user.mapUserZoom &&
                        <GoogleMap places={this.state.places}
                                   lat={this.state.user.mapUserCentreLat}
                                   lng={this.state.user.mapUserCentreLng}
                                   zoom={this.state.user.mapUserZoom}
                                   onBoundsChange={this.onBoundsChange}>
                        </GoogleMap>
                    }

                    {(!this.state.user.mapUserZoom && this.state.loggedIn === 0) &&
                        <GoogleMap places={this.state.places}
                                   lat={48}
                                   lng={-8}
                                   zoom={3}
                                   onBoundsChange={this.onBoundsChange}>
                        </GoogleMap>
                    }

                    {(this.state.loggedIn === -1) &&
                        <Loading />
                    }

                </div>
            </div>
        );
    }
}

export default Map;