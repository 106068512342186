import React, { Component } from 'react';
import firebase from "../util/Firebase";
import { Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { createKey } from "../util/Utils";
import Loading from "../nav/Loading";

class Photo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };

        this.imageRef = firebase.firestore().collection('images');
        this.usersRef = firebase.firestore().collection('users');
        this.tagsRef = firebase.firestore().collection('tags');
    }

    componentDidMount() {
        this.imageRef.doc(this.props.match.params.id).get().then((imageDoc) => {
            if (imageDoc.exists) {
                const image = imageDoc.data();
                this.setState({
                    imageKey: imageDoc.id,
                    title: image.title,
                    createdTimestamp: image.createdTimestamp,
                    description: image.description,
                    place: image.place,
                    placeName: image.placeName,
                    placeVicinity: image.placeVicinity,
                    placeCountry: image.placeCountry,
                    image: image.fileURL,
                    trip: image.trip,
                    owner: image.owner,
                    tagsArray: image.tagsArray,
                    tagObjectsArray: image.tagObjectsArray
                });
            } else {
                console.log("Photo not found");
                return;
            }
        }).then(() => {
            this.setState({loading: false});
        });
    }

    componentDidUpdate(){
        window.onpopstate = e => {
            if(this.state.modal) {
                window.location = '/photo/' + this.props.match.params.id;
            }
        }
    }

    deletePhoto = (imageKey) => {
        if(!firebase.auth().currentUser.uid === this.state.owner) {
            return;
        }
        this.imageRef.doc(imageKey).delete().then(() => {
            this.usersRef.doc(firebase.auth().currentUser.uid).collection("images")
                .doc(imageKey).delete().then(() => {
                    window.location = '/';
            });
         });
    }

    deleteTags = () => {
        let {imageRef, imageKey, tagObjectsArray} = this.state;
        if(!tagObjectsArray) {
            tagObjectsArray = [];
        }
        this.deleteOldTags(imageKey, tagObjectsArray);
    }

    deleteOldTags = (imageKey, tagObjectsArray) => {
        if(tagObjectsArray && tagObjectsArray.length > 0) {
            tagObjectsArray.forEach((oldTag, index) => {
                this.tagsRef.doc(oldTag).collection("images").doc(imageKey).delete().then(() => {
                    this.tagsRef.doc(oldTag).update({ imageCount: firebase.firestore.FieldValue.increment(-1) });
                }).then(() => {
                    this.deletePhoto(imageKey);
                });
            });
        } else {
            this.deletePhoto(imageKey);
        }
    }

    handleClose = () => {
        this.setState({modal: false});
    }

    handleOpen = () => {
        this.setState({modal: true});
    }

    getFormattedTags = (tagsArray) => {
        let tagsToReturn = [];

        tagsArray.forEach((tag, index) => {
            tagsToReturn.push(<Link key={tag} to={"/explore/tag/"+tag}>{tag}</Link>)
            if(index < tagsArray.length - 1) {
                tagsToReturn.push(<span key={'comma' + index}>, </span>);
            }
        });

        return tagsToReturn;
    }

    getFormattedLocation = (placeId, name, vicinity, country) => {
        let locationToReturn = [];
        if (name) {
            locationToReturn.push(<Link key={name} to={"/explore/place/"+createKey(name)}>{name}</Link>);
        }
        if (vicinity) {
            const vicinities = vicinity.split(',');
            vicinities.map(splitVicinity => {
                if (name) {
                    locationToReturn.push(<span key={"comma-one"}>, </span>);
                }
                locationToReturn.push(<Link key={"vicinity"} to={"/explore/location/"+createKey(splitVicinity)}>{splitVicinity}</Link>);
            });
        }
        if (country) {
            if (name || vicinity) {
                locationToReturn.push(<span key={"comma-two"}>, </span>);
            }
            locationToReturn.push(<Link key={"country"} to={"/explore/country/"+createKey(country)}>{country}</Link>);
        }
        return locationToReturn;
    }

    render() {

        const options = { year: 'numeric', month: 'long', day: 'numeric' };

        return (

            <div className="panel panel-default app-min-height">

                {!this.state.loading && <div className={"row"}>
                    <div className={"col-12 col-lg-8 photo-card"} style={{maxHeight: "600px"}}>
                        <img onClick={this.handleOpen.bind(this)}
                             className={"photo-img"}
                             style={{width: "100%", height: "100%"}} src={this.state.image} />
                    </div>
                    <div className={"mt-4 mt-lg-0 col-12 col-lg-4"}>

                        <div className={"photo-note-book"}>
                            <div className={"photo-fact"}>
                                <span className={"photo-fact-name"}>Title:</span>
                                <span className={"photo-fact-detail"}>{this.state.title}</span>
                            </div>
                            <div className={"photo-fact"}>
                                <span className={"photo-fact-name"}>Date:</span>
                                <span className={"photo-fact-detail"}>{this.state.createdTimestamp.toDate().toLocaleDateString('en-GB', options)}</span>
                            </div>
                            <div className={"photo-fact"}>
                                <span className={"photo-fact-name"}>Location:</span>
                                <span className={"photo-fact-detail"}>{this.getFormattedLocation(
                                    this.state.place,
                                    this.state.placeName,
                                    this.state.placeVicinity,
                                    this.state.placeCountry)}</span>
                            </div>
                            {this.state.tagsArray.length > 0 && <div className={"photo-fact"}>
                                <span className={"photo-fact-name"}>Tags:</span>
                                <span className={"photo-fact-detail"}>{this.getFormattedTags(this.state.tagsArray)}</span>
                            </div>}
                            {this.state.trip && <div className={"photo-fact"}>
                                <span className={"photo-fact-name"}>Trip:</span>
                                <span className={"photo-fact-detail"}>None</span>
                            </div>}
                            {this.state.description && <div className={"photo-fact"}>
                                <span className={"photo-fact-name"}>Notes:</span>
                                <span className={"photo-fact-detail"}>{this.state.description}</span>
                            </div>}
                        </div>

                        {(firebase.auth().currentUser && firebase.auth().currentUser.uid === this.state.owner) &&
                        <div className={"admin-panel float-right"}>
                            <Link to={'/edit/' + this.state.imageKey}><button className={"nav-button mr-2"} >Edit</button></Link>
                            <button className={"nav-button nav-button-danger"} onClick={this.deleteTags.bind(this)}>Delete</button>
                        </div>}

                    </div>
                </div>}

                <Modal show={this.state.modal} onHide={this.handleClose.bind(this)}>
                    <img style={{width: "100%", height: "100%"}}
                         src={this.state.image}
                         onClick={this.handleClose.bind(this)} />
                </Modal>

                {this.state.loading &&
                    <Loading />
                }

            </div>
        );
    }
}

export default Photo;