import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {

    render() {

        let currentYear = new Date().getFullYear();

        return (
            <div className="footer">
                <div className={"float-right"}><small>ExplorePhotos &copy; {currentYear}</small></div>
            </div>
        );
    }
}
  
export default Footer;