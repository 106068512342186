import React, {Component} from "react";
import GoogleMapReact from "google-map-react";
import "./Map.css";
import {Link} from "react-router-dom";
import {createKey} from "../util/Utils";

class GoogleMap extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const Marker = ({ id, name }) => <Link to={`/explore/place/${createKey(name)}`}><div class={"marker1"}></div></Link>;

        return (
            <GoogleMapReact
                bootstrapURLKeys={{key: "AIzaSyCb8xifKA7VtGPWOE11Qb5eXA-8T8vumKM"}}
                defaultCenter={{lat: this.props.lat, lng: this.props.lng}}
                defaultZoom={this.props.zoom}
                onBoundsChange={this.props.onBoundsChange}
            >
                {this.props.places.map((place, index) => (
                    <Marker
                        lat={place.lat}
                        lng={place.lon}
                        name={place.name}
                        id={place.key}
                    />
                ))}
            </GoogleMapReact>
        )
    }

}

export default GoogleMap;