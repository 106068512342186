import React, { Component } from 'react';
import firebase from '../util/Firebase';
import startFirebaseUI from './FirebaseUI'

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  renderRedirect = () => {
    firebase.auth().onAuthStateChanged(function(user) {
      if(user) {
        window.location = '/';
      } else {
        startFirebaseUI ('#firebaseui');
      }
    });
  }
  
  render() {
    return (
      <div className="app-min-height">
        {this.renderRedirect()}
        <div id="firebaseui"></div>
      </div>
    );
  }
}

export default App;