import React, { Component } from 'react';
import history from "../util/History";
import { getFormattedLocation } from '../util/Utils';
import { Link } from 'react-router-dom';

class Polaroid extends Component {

    componentDidMount() {
    }

    getFormattedLocation = () => {
        return getFormattedLocation(this.props.image.placeName, this.props.image.placeVicinity, this.props.image.placeCountry);
    }

    navigateTo = () => {
        history.push('/photo/' + this.props.image.key);
    }

    render() {
        return (

            <Link className="polaroid-container" to={'/photo/' + this.props.image.key}
                 style={{transform: this.props.transform, cursor: "pointer"}}>
                <div className={"img-container"}>
                    <div className={"img-display"}>
                        {this.props.image.thumbnailURL && <img className="img-display" src={this.props.image.thumbnailURL}/>}
                        {(!this.props.image.thumbnailURL && this.props.image.fileURL) &&
                            <img className="img-display" src={this.props.image.fileURL}/>}
                    </div>
                    <div className={"img-description"}>
                        <div className={this.getFormattedLocation().length > 34 ? 'img-description-text-md' : 'img-description-text-sm'}>
                            <span className={this.getFormattedLocation().length > 40 ? 'text-size-sm' : 'text-size-md'}>{this.getFormattedLocation()}</span>
                        </div>
                    </div>
                </div>
            </Link>

        );
    }
}

export default Polaroid;