import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { getFormattedLocationPlace, getFormattedLocation } from '../util/Utils';
/* global google */

class PubFinder extends React.PureComponent {

  constructor(props) {
    super(props);

    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
  }

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteInput.current, {}
    );

    this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
  }

  getPlaceholder = () => {
    let placeholder = "Example: Stonehenge, Taj Mahal, Empire State Building";
    if(getFormattedLocation(this.props.placeName, this.props.placeVicinity, this.props.placeCountry)) {
      placeholder = getFormattedLocation(this.props.placeName, this.props.placeVicinity, this.props.placeCountry);
    }
    return placeholder;
  }

  render() {
    return (
      <Form onSubmit={this.handlePlaceChanged}>
        <Form.Row>
          <Col>
            <Form.Control ref={this.autocompleteInput}
                          placeholder={this.getPlaceholder()}
            />
          </Col>
        </Form.Row>
      </Form>
    );
  }

  handlePlaceChanged = () => {
    this.props.handlePlaceChanged(this.autocomplete.getPlace());
  };
}

export default PubFinder;
