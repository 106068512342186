import React, {Component} from 'react';
import firebase from '../util/Firebase';
import {getFormattedLocationPlace, getFormattedLocation, unique, createKey} from '../util/Utils';
import PubFinder from "./PlaceFinder";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import '../app/App.css';

class Upload extends Component {

    constructor(props) {
        super(props);

        this.state = {
            readyToCreate: true,
            title: '',
            description: '',
            image: '',
            editMode: false,
            imageChanged: false,
            placeId: '',
            place: '',
            placeName: '',
            placeVicinity: '',
            placeCountry: '',
            tagsArray: [],
            loggedIn: 0
        };

        this.imagesRef = firebase.firestore().collection('images');
        this.placesRef = firebase.firestore().collection('places');
        this.usersRef = firebase.firestore().collection('users');
        this.tagsRef = firebase.firestore().collection('tags');
    }

    componentDidMount() {

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.usersRef.doc(user.uid).get().then(userDoc => {
                    if (userDoc.exists) {
                        if(userDoc.data().isAdmin) {
                            this.setState({loggedIn: 2});
                        } else {
                            this.setState({loggedIn: 1});
                        }
                    }
                });
            } else {
                this.setState({loggedIn: 0});
            }
        });

        if(this.props.match.params.id) {
            this.setState({editMode: true});
            this.imagesRef.doc(this.props.match.params.id).get().then((imageDoc) => {
                if (imageDoc.exists) {
                    const image = imageDoc.data();
                    this.setState({
                        imageKey: imageDoc.id,
                        title: image.title,
                        description: image.description,
                        placeId: image.place,
                        placeName: image.placeName,
                        placeVicinity: image.placeVicinity,
                        placeCountry: image.placeCountry,
                        tagsArray: image.tagsArray,
                        image: image.fileURL,
                        trip: image.trip,
                        owner: image.owner,
                        thumbURL: image.thumbnailURL,
                        fullURL: image.fileURL,
                        tagObjectsArray: image.tagObjectsArray,
                        oldTagObjectsArray: image.tagObjectsArray
                    });
                } else {
                    console.log("Image not found");
                    return;
                }
            });
        }
    }

    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState(state);
    }

    addTag = (e) => {

        e.preventDefault();

        const tag = this.state.tag;

        if(!tag) {
            return;
        }

        let tagsArray = this.state.tagsArray;
        if(!tagsArray) {
            tagsArray = [];
        }
        tagsArray.push(tag.trim().toLowerCase());

        this.setState({
            tagsArray,
            tag: ''
        });
    }

    onHandleUpload = (e) => {

        if(!e.target.files[0]) {
            return;
        }

        let fileSize = (e.target.files[0].size) / 1024 / 1024;
        if (fileSize > 20) {
            alert('File size exceeds 20 MB');
        } else {

            const fullSize = e.target.files[0];
            const image = URL.createObjectURL(fullSize);

            this.setState({
                image,
                fullSize,
                imageChanged: true
            })

            this.resizeImage2(e.target.files[0]).then((resizedImage) => {
                this.setState({
                    thumbnail: resizedImage
                })
            }).catch(function (err) {
                console.error(err);
            });
        }
    }

    resizeImage2 = (file) => {
        var reader = new FileReader();
        var image = new Image();
        var canvas = document.createElement('canvas');
        var dataURItoBlob = function (dataURI) {
            var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
                atob(dataURI.split(',')[1]) :
                unescape(dataURI.split(',')[1]);
            var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var max = bytes.length;
            var ia = new Uint8Array(max);
            for (var i = 0; i < max; i++)
                ia[i] = bytes.charCodeAt(i);
            return new Blob([ia], { type: mime });
        };
        var resize = function () {
            canvas.width = 720;
            canvas.height = 540;
            canvas.getContext('2d').drawImage(image, 0, 0, 720, 540);
            var dataUrl = canvas.toDataURL('image/jpeg');
            return dataURItoBlob(dataUrl);
        };
        return new Promise(function (ok, no) {
            if (!file.type.match(/image.*/)) {
                no(new Error("Not an image"));
                return;
            }
            reader.onload = function (readerEvent) {
                image.onload = function () { return ok(resize()); };
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
        });
    };

    onSubmit = (e) => {

        e.preventDefault();

        if (!e) {
            return;
        }

        let {title, description, tagsArray, fullSize, place, placeId} = this.state;

        if (!title) {
            title = "";
        }

        if (!description) {
            description = "";
        }

        if(!this.state.editMode && !fullSize) {
            alert("Please select a photo to be uploaded");
            return;
        }

        if(!this.state.readyToCreate || !title || (!place && !placeId)) {
            return;
        } else {
            this.setState({readyToCreate: false, tagsArray, title, description}, () => {
                this.saveData();
            });
        }
    }

    saveData = () => {
        this.savePlace();
    };

    createImageRefs = () => {

        const { fullSize, thumbnail, title, description, tagsArray, place, thumbURL, fullURL, oldTagObjectsArray } = this.state;

        if(!this.state.editMode) {
            this.imagesRef.add({}).then((imageRef) => {
                this.uploadImage(imageRef, fullSize, thumbnail, title,
                    description, tagsArray, place, '', '', oldTagObjectsArray);
            }).catch((error) => {
                console.error("Error creating image location: " + error);
            });
        } else {
            this.imagesRef.doc(this.props.match.params.id).get().then((imageRef) => {
                const data = {imageRef, fullSize, thumbnail, title,
                    description, tagsArray, place, thumbURL, fullURL, oldTagObjectsArray};
                //console.log(data);
                this.uploadImage(imageRef, fullSize, thumbnail, title,
                    description, tagsArray, place, thumbURL, fullURL, oldTagObjectsArray);
            }).catch((error) => {
                console.error("Error getting image location: " + error);
            });
        }
    }

    uploadImage = (imageRef, fullSize, thumbnail, title,
                   description, tagsArray, place, thumbURL, fullURL, oldTagObjects) => {

        if (this.state.imageChanged) {

            const storage = firebase.storage();
            const storageRef = storage.ref();
            const locationImagesStorageRef = storageRef.child('images/locations/' + imageRef.id);
            const locationThumbnailsStorageRef = storageRef.child('images/locations/thumbnails/' + imageRef.id);

            locationImagesStorageRef.put(fullSize).then((snapshot) => {
                locationImagesStorageRef.getDownloadURL().then((fullSizeURL) => {

                    locationThumbnailsStorageRef.put(thumbnail).then((snapshot) => {
                        locationThumbnailsStorageRef.getDownloadURL().then((thumbnailURL) => {

                            this.saveImage(imageRef, fullSize, thumbnail, title,
                                description, tagsArray, place, thumbnailURL, fullSizeURL, oldTagObjects);

                        }).catch((error) => {
                            console.error("Error saving location image: ", error);
                        });
                    });
                }).catch((error) => {
                    console.error("Error saving location image: ", error);
                });
            });

        } else {
            this.saveImage(imageRef, fullSize, thumbnail, title,
                description, tagsArray, place, thumbURL, fullURL, oldTagObjects);
        }

    }

    saveImage = (imageRef, fullSize, thumbnail, title, description,
                 tagsArray, place, thumbnailURL, fileURL, oldTagObjects) => {

        try {

            const tagObjects = this.generateTagObjects();
            let tagObjectsArray = [];
            tagObjects.map(to => {
                tagObjectsArray.push(to.key);
            });

            if (!tagsArray) {
                tagsArray = [];
            }

            if (!oldTagObjects) {
                oldTagObjects = [];
            }

            if (!this.state.editMode) {

                this.imagesRef.doc(imageRef.id).set({
                    title, description, thumbnailURL, fileURL,
                    owner: firebase.auth().currentUser.uid,
                    updatedTimestamp: new Date(),
                    createdTimestamp: new Date(),
                    tagsArray, tagObjectsArray, trip: null, place: place.id,
                    placeName: place.name, placeVicinity: place.vicinity, placeCountry: place.country
                }).then((imgDocRef) => {
                    this.insertImageRefs(imageRef, tagObjects, tagObjectsArray, oldTagObjects);
                }).catch((error) => {
                    console.error("Error creating image data: " + error);
                });

            } else {

                const data = {
                    title, description, thumbnailURL, fileURL,
                    updatedTimestamp: new Date(),
                    tagsArray, tagObjectsArray, trip: null, place: place.id,
                    placeName: place.name, placeVicinity: place.vicinity, placeCountry: place.country
                };

                console.log(data);

                this.imagesRef.doc(imageRef.id).update(data).then((imgDocRef) => {
                    this.insertImageRefs(imageRef, tagObjects, tagObjectsArray, oldTagObjects);
                }).catch((error) => {
                    console.error("Error updating image data: " + error);
                });

            }

        } catch(ex) {
            console.log("An error occurred saving the image: " + ex);
        }
    }

    savePlace = async () => {

        const placeId = this.state.placeId;

        if(this.state.loggedIn < 1) {
            return;
        }

        const newPlaceRef = this.placesRef.doc(placeId);
        const existingPlace = await newPlaceRef.get();

        if (existingPlace.exists) {
            this.setState({place: existingPlace.data()}, () => {
                this.createImageRefs();
            });
        } else {

            const place = this.state.place;

            const placeData = {
                id: newPlaceRef.id, enabled: true,
                lat: place.lat, lon: place.lng,
                createdTimestamp: new Date(),
                updatedTimestamp: new Date(),
                name: place.name, vicinity: place.vicinity, country: place.country
            }

            this.setState({place: placeData}, () => {
                newPlaceRef.set(placeData, {merge: true}).then(() => {
                    this.createImageRefs();
                });
            });
        }
    };

    handlePlaceChanged = (place) => {

        if (!place || !place.place_id) {
            console.error("Place ID is missing, so skipping this place.");
            return;
        }

        let country = 'Unknown Place';
        place.address_components.map(r => {
           if(r.types.includes('country')) {
               country = r.long_name;
           }
        });

        this.setState({
            placeId: place.place_id || "",
            place: {
                placeId: place.place_id || "",
                rating: place.rating || 0,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
                name: place.name || "Unknown Place",
                vicinity: place.vicinity || "Unknown Place",
                country: country
            }
        });
    }

    insertImageRefs = (imageRef, tagObjects, tagObjectsArray, oldTagObjects) => {
        this.usersRef.doc(firebase.auth().currentUser.uid).collection("images").doc(imageRef.id).set({
            id: imageRef.id,
            createdTimestamp: new Date(),
            owner: true
        }, { merge: true }).then(() => {
            this.deleteOldTags(imageRef, tagObjects, tagObjectsArray, oldTagObjects);
        });
    }

    generateTagObjects = () => {

        let tagObjects = [];

        const place = this.state.place;
        let tagsArray = this.state.tagsArray;

        if(place.name !== "Unknown Place") {
            tagObjects.push({
                key: createKey(place.name),
                name: place.name.trim(),
                type: 'place'
            });
        }

        if(place.vicinity !== "Unknown Place") {
            let locations = place.vicinity.split(",");
            if(!locations) {
                locations = [];
            }
            locations.map(l => {
                tagObjects.push({
                    key: createKey(l),
                    name: l.trim(),
                    type: 'location'
                });
            });
        }

        if(place.country !== "Unknown Place") {
            tagObjects.push({
                key: createKey(place.country),
                name: place.country.trim(),
                type: 'country'
            });
        }

        if(!tagsArray) {
            tagsArray = [];
        }

        tagsArray.map(t => {
            tagObjects.push({
                key: createKey(t),
                name: t,
                type: 'tag'
            });
        });

        return tagObjects;
    }

    deleteOldTags = (imageRef, tagObjects, tagObjectsArray, oldTagObjects) => {

        try {

            let tagsToAdd = [];
            let tagsToDelete = [];

            if (!oldTagObjects) {
                oldTagObjects = [];
            } else {
                oldTagObjects = unique(oldTagObjects);
            }

            tagObjectsArray = unique(tagObjectsArray);

            if (tagObjectsArray) {
                tagObjectsArray.map((tag) => {
                    if (oldTagObjects && !oldTagObjects.includes(tag)) {
                        tagsToAdd.push(tag);
                    }
                });
            }

            if (oldTagObjects) {
                oldTagObjects.map((tag) => {
                    if (tagObjectsArray && !tagObjectsArray.includes(tag)) {
                        tagsToDelete.push(tag);
                    }
                });
            }

            console.log(tagsToAdd);
            console.log(tagsToDelete);

            if (tagsToAdd.length == 0 && tagsToDelete.length == 0) {
                window.location = "/photo/" + imageRef.id;
            }

            this.setState({
                tagsAddedCount: 0,
                tagsToAdd: tagsToAdd.length,
                tagsDeletedCount: 0,
                tagsToDelete: tagsToDelete.length
            }, () => {

                if (tagsToDelete && tagsToDelete.length > 0) {
                    tagsToDelete.forEach((oldTag, index) => {
                        this.tagsRef.doc(oldTag).get().then((tagDoc) => {
                            if (tagDoc.exists) {
                                this.tagsRef.doc(oldTag).collection("images").doc(imageRef.id).delete().then(() => {
                                    this.tagsRef.doc(oldTag).update({imageCount: firebase.firestore.FieldValue.increment(-1)}).then(() => {

                                        let tagsDeletedCount = this.state.tagsDeletedCount + 1;
                                        let tagsAddedCount = this.state.tagsAddedCount;
                                        if (tagsDeletedCount >= tagsToDelete.length && tagsAddedCount >= tagsToAdd.length) {
                                            window.location = "/photo/" + imageRef.id;
                                        } else {
                                            this.setState({tagsDeletedCount});
                                        }

                                    });
                                });
                            }
                        });
                    });
                }

                tagsToAdd.map(tag => {

                    let tagToAdd = {};
                    tagObjects.map(t => {
                        if (t.key === tag) {
                            tagToAdd = t;
                        }
                    });

                    this.saveTag(imageRef, tagToAdd, tagsToAdd.length, tagsToDelete.length);
                });

            });
        } catch(ex) {
            console.log("An error occurred deleting old tags: " + ex);
        }
    }

    saveTag = (imageRef, tag, tagsToAddLength, tagsToDeleteLength) => {

        tag['createdTimestamp'] = new Date();

        this.tagsRef.doc(tag.key).get().then((tagDoc) => {
            if (tagDoc.exists) {
                this.tagsRef.doc(tag.key).collection("images").doc(imageRef.id).set({
                    id: imageRef.id,
                    createdTimestamp: new Date()
                }).then(() => {
                    this.tagsRef.doc(tag.key).update({imageCount: firebase.firestore.FieldValue.increment(1)}).then(() => {

                        let tagsDeletedCount = this.state.tagsDeletedCount;
                        let tagsAddedCount = this.state.tagsAddedCount + 1;
                        console.log('update: ' + tagsDeletedCount + ', ' + tagsToDeleteLength + ', ' + tagsAddedCount + ', ' + tagsToAddLength);
                        if(tagsDeletedCount >= tagsToDeleteLength && tagsAddedCount >= tagsToAddLength) {
                            window.location = "/photo/"+imageRef.id;
                        } else {
                            this.setState({tagsAddedCount});
                        }

                    });
                });
            } else {
                tag['imageCount'] = 1;
                this.tagsRef.doc(tag.key).set(tag, {merge: true}).then(() => {
                    this.tagsRef.doc(tag.key).collection("images").doc(imageRef.id).set({
                        id: imageRef.id,
                        createdTimestamp: new Date()
                    }).then(() => {

                        let tagsDeletedCount = this.state.tagsDeletedCount;
                        let tagsAddedCount = this.state.tagsAddedCount + 1;
                        console.log('add: ' + tagsDeletedCount + ', ' + tagsToDeleteLength + ', ' + tagsAddedCount + ', ' + tagsToAddLength);
                        if(tagsDeletedCount >= tagsToDeleteLength && tagsAddedCount >= tagsToAddLength) {
                            window.location = "/photo/"+imageRef.id;
                        } else {
                            this.setState({tagsAddedCount});
                        }

                    });
                });
            }
        });
    }

    getFormattedLocation = () => {
        let formattedLocation = '';
        if(this.state.place) {
            formattedLocation = getFormattedLocationPlace(this.state.place);
        } else if(!this.state.place && this.state.placeId) {
            formattedLocation = getFormattedLocation(this.state.placeName, this.state.placeVicinity, this.state.placeCountry);
        }
        return formattedLocation;
    }

    deleteTag = (tag) => {

        if(!tag) {
            return;
        }

        tag = tag.trim().toLowerCase();

        let tagsArray = this.state.tagsArray;

        const index = tagsArray.indexOf(tag);
        if (index > -1) {
            tagsArray.splice(index, 1);
        }

        this.setState({
            tagsArray,
            tag: ''
        });
    }

    render() {
        return (
            <div className={"row app-min-height"}>

                <div className="panel-body col-12 col-lg-6">
                    <div className="polaroid-container ml-2 ml-lg-1">
                        <div className={"img-container"}>
                            <div className={"upload-img-background"}>

                                    {!this.state.image && <div className={"img-display-upload-container"}>
                                        <label className={"upload-img-label"} htmlFor="image-input">Attach photo</label>
                                    </div>}

                                    {this.state.image && <div className={"img-display-upload-container"} style={{border: "none"}}>
                                        <img id="img-preview" className={"upload-img-background"} src={this.state.image} />
                                        <label className={"upload-img-label-hidden"} htmlFor="image-input"></label>
                                    </div>}

                            </div>
                            <div className={"img-description"} style={{height: "90px"}}>
                                <div className={this.getFormattedLocation().length > 28 ? 'img-description-text-md' : 'img-description-text-sm'}>
                                    <span className={this.getFormattedLocation().length > 40 ? 'text-size-xs' : 'text-size-sm'}>{this.getFormattedLocation()}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel-body col-12 col-lg-6 mt-3 mt-lg-1">

                    <form onSubmit={this.onSubmit}>

                        <input onChange={this.onHandleUpload}
                               type="file"
                               className="input-file"
                               name="image"
                               id="image-input"/>

                        <div className="form-group">
                            <label className={"upload-label-font"} htmlFor="title">Title <span className={"required"}>*</span></label>
                            <input type="text" className="form-control" name="title" value={this.state.title}
                                   required
                                   onChange={this.onChange}
                                   placeholder="Describe the scene in a few words"/>
                        </div>

                        <div className="form-group">
                            <label className={"upload-label-font"} htmlFor="description">Notes</label>
                            <textArea className="form-control"
                                      name="description"
                                      onChange={this.onChange}
                                      placeholder="What is the story of the photo?"
                                      cols="80" rows="3">{this.state.description}</textArea>
                        </div>

                        <div className="form-group">
                            <label className={"upload-label-font"} htmlFor="title">Tags</label>
                            {this.state.tagsArray && <div className={"mb-2"}>
                                {this.state.tagsArray.map((t) => (
                                    <span onClick={this.deleteTag.bind(this, t)} className={"explore-place-tag nav-button-danger mr-2"}>{t}</span>
                                ))}
                            </div>}

                            <Form.Row>
                                <Col>
                                    <Form.Control type="text" placeholder="Example: beach, harbour, sunset"
                                                  name="tag" value={this.state.tag} onChange={this.onChange}/>
                                </Col>
                                <Col xs="auto">
                                    <button className="nav-button" onClick={this.addTag.bind(this)}>Add</button>
                                </Col>
                            </Form.Row>
                        </div>

                        <div className="form-group">
                            <label className={"upload-label-font"} htmlFor="title">Location <span className={"required"}>*</span></label>
                            <PubFinder handlePlaceChanged={this.handlePlaceChanged}
                                       place={this.state.place}
                                       placeName={this.state.placeName}
                                       placeVicinity={this.state.placeVicinity}
                                       placeCountry={this.state.placeCountry} />
                        </div>

                        <div className="float-right">
                            {this.state.readyToCreate && <button type="submit" className={"nav-button"}>Save</button>}
                            {!this.state.readyToCreate && <div className={"saving-button"}>Saving...</div>}
                        </div>

                    </form>
                </div>
            </div>
        );
    }
}

export default Upload;