import React, { Component } from 'react';
import './Nav.css';
import Row from "react-bootstrap/Row";

class Loading extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Row className={"drawer-container"}>
                <div className={"loading-wheel"}>Loading...</div>
            </Row>
        )
    }
}

export default Loading;