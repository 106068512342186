import React, { Component } from 'react';
import '../app/App.css';
import firebase from '../util/Firebase';
import Display from "../display/Display";

class CreateTrip extends Component {

    constructor(props) {
        super(props);
        this.state = {
            images: [],
            imagesMap: {}
        };

        // DB references
        this.imageRef = firebase.firestore().collection('images').limit(50);
        this.imageUnsub = null;
    }

    componentDidMount() {
        this.imageUnsub = this.imageRef.orderBy("createdTimestamp", "desc").onSnapshot(this.onImagesUpdate);
    }

    onImagesUpdate = (querySnapshot) => {

        const images = [];
        const imagesMap = {};

        querySnapshot.forEach((doc) => {

            const { createdTimestamp, description,
                fileName, thumbnailURL, fileURL,
                location, owner, title, trip } = doc.data();

            const dataToStore = { key: doc.id, createdTimestamp,
                description, fileName, thumbnailURL, fileURL, location, owner, title, trip };

            images.push(dataToStore);
            imagesMap[doc.id] = dataToStore;
        });

        this.setState({
            images,
            imagesMap
        });
    }

    render() {

        return (
            <div className="panel panel-default app-min-height">
                <div>
                    <span>Create Trip</span>
                    <div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateTrip;