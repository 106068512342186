import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import firebase from '../util/Firebase';
import './Header.css'

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notify: false,
            loggedIn: 0
        };
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    loggedIn: 2
                });
            } else {
                this.setState({
                    loggedIn: 1
                });
            }
        });
    }

    render() {

        let loginButton = '';
        let logoutButton = '';

        if(this.state.loggedIn == 1) {
            loginButton = <Link className="login-button" to={'/auth'}>Login</Link>;
        } else if(this.state.loggedIn == 2) {
            loginButton = <span><Link className={"alt-link"} to={`/profile/${firebase.auth().currentUser.uid}`}>{firebase.auth().currentUser.displayName}</Link>
                {/*<span> | </span>*/}
            </span>;
            // logoutButton = <div className="logout-button" onClick={this.logoutUser}>Logout</div>;
        }

        return (
            <div className="header">
                <div onClick={this.navigateToHome} className="site-title">
                    <span style={{cursor: "pointer", fontSize: "1.4em"}}>ExplorePhotos</span>
                </div>
                <div className="user-status" style={{paddingTop: "12px"}}>
                    {loginButton}{logoutButton}
                </div>
            </div>
        );
    }
}
  
export default Header;