
// Firebase constants
export const API_KEY = "AIzaSyCDfEgkl225KjyQTF3FJ3FtrvW7ubKUCvw";
export const AUTH_DOMAIN = "travel-trip-872cf.firebaseapp.com";
export const DATABASE_URL = "https://marketplace-77db8.firebaseio.com";
export const PROJECT_ID = "travel-trip-872cf";
export const STORAGE_BUCKET = "travel-trip-872cf.appspot.com";
export const MESSAGING_SENDER_ID = "687564593852";
export const APP_ID = "1:687564593852:web:1d80b00b6f63641ecfceb9";
export const MEASUREMENT_ID = "G-XW26F6SKL3";

// Month names
export const MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
