import React, { Component } from 'react';
import firebase from "../util/Firebase";
import { Link, withRouter } from 'react-router-dom';
import './Nav.css';
import {uuid} from "../util/Utils";

class Nav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: 0,
            pageSelected: '/'
        };
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            this.setState({pageSelected: location.pathname});
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    loggedIn: 2
                });
            } else {
                this.setState({
                    loggedIn: 1
                });
            }
        });

        // Handle the initial page load
        let currentURL = window.location.href;
        let currentURLArr = currentURL.split('/');
        const page = '/' + currentURLArr[3];
        this.setState({pageSelected: page});
    }

    onTripsPage = () => {
        let page = this.state.pageSelected;
        if(page === "/trips") {
            return true;
        }
        return false;
    }

    render() {

        const uploadLinkTarget = {
            pathname: "/upload",
            key: uuid(),
            state: {
                applied: true
            }
        };

        const exploreLinkTarget = {
            pathname: "/explore",
            key: uuid(),
            state: {
                applied: true
            }
        };

        const createLinkTarget = {
            pathname: "/create",
            key: uuid(),
            state: {
                applied: true
            }
        };

        return (
            <>
                <div className={"d-inline-block"}>
                    <ul className={"nav"}>
                        <Link to={'/'} className={"/" !== this.state.pageSelected ? "nav-button mr-2" : "nav-button selected mr-2"}>Latest</Link>
                        <Link to={exploreLinkTarget} className={"/explore" !== this.state.pageSelected ? "nav-button mr-2" : "nav-button selected mr-2"}>Explore</Link>
                        <Link to={'/map'} className={"/map" !== this.state.pageSelected ? "nav-button mr-2" : "nav-button selected mr-2"}>Map</Link>
                        <Link to={'/trips'} className={"/trips" !== this.state.pageSelected ? "nav-button mr-2" : "nav-button selected mr-2"}>Trips</Link>
                    </ul>
                </div>
                <div className={"d-inline-block"} style={{float: "right"}}>
                    <ul className={"nav"}>
                        {(this.state.loggedIn === 2 && !this.onTripsPage()) &&
                            <Link to={uploadLinkTarget} className={"/upload" !== this.state.pageSelected ? "nav-button" : "nav-button selected"}>Upload</Link>
                        }
                        {(this.state.loggedIn === 2 && this.onTripsPage()) &&
                            <Link to={createLinkTarget} className={"/create" !== this.state.pageSelected ? "nav-button" : "nav-button selected"}>Create</Link>
                        }
                    </ul>
                </div>
            </>
        );
    }
}

export default withRouter(Nav);