import React, { Component } from 'react';
import '../app/App.css';
import Polaroid from "../element/Polaroid";
import './Display.css';
import {capitalize, capitalizeAllWords} from "../util/Utils";

class Display extends Component {

    constructor(props) {
        super(props);

        this.state = {
            locations: [],
            locationsMap: {},
            noImages: false
        };

    }

    onChange = (e) => {
        const state = this.state
        state[e.target.name] = e.target.value;
        this.setState(state);
    }

    getRandomTransform = (index) => {
        const randomNumber = Math.random();
        if(index % 2) {
            return "rotate(" + randomNumber + "deg)";
        } else {
            return "rotate(-" + randomNumber + "deg)";
        }
    }

    generateTitle = () => {

        if(!this.props.type || !this.props.id) {
            return '';
        }
        let htmlToReturn = [];
        if(this.props.type !== 'tag') {
            htmlToReturn.push(<span style={{fontWeight: "700", fontSize: "large"}}>{capitalizeAllWords(this.props.id.replaceAll('-', ' '))}</span>);
            return htmlToReturn;
        } else {
            htmlToReturn.push(<span style={{fontWeight: "700", fontSize: "large"}}>{this.props.id.replaceAll('-', ' ')}</span>);
            return htmlToReturn;
        }
    }

    render() {

        return (
            <div className={"app-min-height"}>

                {this.generateTitle() && <div className={"mb-4"} style={{borderBottom: "solid 1px #85858559", marginTop: "-5px"}}>
                    <span>{this.generateTitle()}</span>
                </div>}

                <div className="panel panel-default">

                    <div className={"row drawer-container"}>

                        {(this.props.images && this.props.images.length > 0) && this.props.images.map((image, index) => (
                            <Polaroid key={index}
                                      image={image}
                                      transform={this.getRandomTransform(index)} />
                        ))}
                        {(this.props.images && this.props.images.length === 0 && !this.props.noImages) && <div className={"loading-wheel"}>Loading...</div>}
                        {(this.props.noImages) && <div className={"loading-wheel"}>No tagged images found</div>}

                    </div>

                </div>

            </div>
        );
    }
}

export default Display;