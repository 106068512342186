import firebase from '../util/Firebase';
import * as firebaseui from "firebaseui";

const uiConfig = ({
  signInSuccessUrl: '/auth',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ],
  tosUrl: '/terms-of-service'
});

const firebaseUI = new firebaseui.auth.AuthUI(firebase.auth());

const startFirebaseUI = function (elementId) {
  firebaseUI.start(elementId, uiConfig);
}

let userCreated = false;

firebase.auth().onAuthStateChanged(function(user) {
  if(user) {
    firebase.firestore().collection("users").where("uid", "==", user.uid).get().then(function(querySnapshot) {
      querySnapshot.forEach(function(doc) {
        userCreated = true;
      });
    }).then(() => {createNewUser(user)});
  }
});

function createNewUser(user) {
  if(!userCreated) {
    firebase.firestore().collection("users").doc(user.uid).set({
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      isAdmin: false,
    }, { merge: true });
  }
}

export default startFirebaseUI;