import React, { Component } from 'react';
import firebase from "../util/Firebase";
import Display from "../display/Display";
import { Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import './Explore.css';
import {uuid} from "../util/Utils";
import Loading from "../nav/Loading";

class Explore extends Component {

    constructor(props) {
        super(props);

        this.state = {
            images: [],
            imagesMap: {},
            loading: true,
            noImages: false
        };

        // DB references
        this.imagesRef = firebase.firestore().collection('images');
        this.tagsRef = firebase.firestore().collection('tags');

    }

    componentDidMount() {
        let type = this.props.match.params.type;
        let id = this.props.match.params.id;

        if(!type || !id) {
            this.setState({
                exploreMode: true
            });
            this.getTags();
        } else {
            this.getImageKeys(this.tagsRef, id);
        }
    }

    componentDidUpdate(){
        window.onpopstate = e => {
            this.setState({
                exploreMode: true
            }, () => {
                this.getTags();
            });
        }
    }

    getImageKeys = (ref, id) => {
        const imageKeys = [];
        ref.doc(id)
            .collection("images").get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    imageKeys.push(doc.data().id);
                });
            }).then(() => {

                const images = [];
                const imagesMap = {};

                if(imageKeys.length === 0) {
                    this.setState({noImages: true})
                }

                imageKeys.forEach((imageKey) => {
                    this.imagesRef.doc(imageKey).get().then((imageDoc) => {

                        if(imageDoc.exists) {
                            const { createdTimestamp, description,
                                fileName, thumbnailURL, fileURL,
                                location, owner, title, trip, placeName, placeVicinity, placeCountry } = imageDoc.data();

                            const dataToStore = { key: imageDoc.id, createdTimestamp,
                                description, fileName, thumbnailURL, fileURL,
                                location, owner, title, trip, placeName, placeVicinity, placeCountry };

                            images.push(dataToStore);
                            imagesMap[imageDoc.id] = dataToStore;

                            this.setState({images, imagesMap});
                        }
                    });
                });
            });
    }

    getTags = () => {
        const tags = [];
        const tagsMap = {};
        this.tagsRef.where("imageCount", ">", 0)
            .orderBy("imageCount", "desc")
            .orderBy("name", "asc")
            .get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const { key, name, type, imageCount } = doc.data();
                const dataToStore = { id: doc.id, key, name, type, imageCount };
                tags.push(dataToStore);
                tagsMap[doc.id] = dataToStore;
            });
        }).then(() => {
            this.setState({
                tags,
                tagsMap,
                loading: false,
                images: [],
                imagesMap: {}
            });
        });
    }

    getLinkTarget = (link) => {
        const linkObject = {
            pathname: link,
            key: uuid(),
            state: {
                applied: true
            }
        }
        return linkObject;
    }

    setViewMode = () => {
        this.setState({
            exploreMode: false
        }, () => {
            this.getImageKeys(this.tagsRef, this.props.match.params.id);
        });
    }

    render() {

        return (
            <div className="app-min-height">
                {!this.state.exploreMode &&
                    <>
                        <Display images={this.state.images}
                                 imagesMap={this.state.imagesMap}
                                 noImages={this.state.noImages}
                                 type={this.props.match.params.type}
                                 id={this.props.match.params.id}/>
                    </>
                }
                {(this.state.exploreMode && !this.state.loading) && <div>
                    <div className="panel panel-default">
                        <Row>
                            <div className={"explore-card col-12"}>
                                <div className={"explore-title"}>Tags</div>
                                <div>
                                    {this.state.tags.filter((t) => {return t.type === 'tag'}).map((tag) => (
                                        <Link to={'/explore/tag/' + tag.id}
                                              onClick={this.setViewMode.bind(this)}
                                              className={"explore-place-tag"}>{tag.name}</Link>
                                    ))}
                                </div>
                            </div>
                            <div className={"explore-card col-12"}>
                                <div className={"explore-title"}>Countries</div>
                                <div>
                                    {this.state.tags.filter((t) => {return t.type === 'country'}).map((country) => (
                                        <Link to={'/explore/country/' + country.id}
                                              onClick={this.setViewMode.bind(this)}
                                              className={"explore-place-tag"}>{country.name}</Link>
                                    ))}
                                </div>
                            </div>
                            <div className={"explore-card col-12"}>
                                <div className={"explore-title"}>Locations</div>
                                <div>
                                    {this.state.tags.filter((t) => {return t.type === 'location' && t.imageCount > 0}).map((location) => (
                                        <Link to={this.getLinkTarget('/explore/location/' + location.id)}
                                              onClick={this.setViewMode.bind(this)}
                                              className={"explore-place-tag"}>{location.name}</Link>
                                    ))}
                                </div>
                            </div>
                            <div className={"explore-card col-12"}>
                                <div className={"explore-title"}>Places</div>
                                <div>
                                    {this.state.tags.filter((t) => {return t.type === 'place' && t.imageCount > 0}).map((place) => (
                                        <Link to={'/explore/place/' + place.id}
                                              onClick={this.setViewMode.bind(this)}
                                              className={"explore-place-tag"}>{place.name}</Link>
                                    ))}
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>}
                {(this.state.exploreMode && this.state.loading) && <div className="panel panel-default">
                    <Loading />
                </div>}
            </div>
        );
    }
}

export default Explore;