import { MONTH_NAMES } from './Constants';

export const getImage = (productImage) => {
  if (productImage === 'no-image') {
    return 'https://firebasestorage.googleapis.com/v0/b/marketplace-77db8.appspot.com/o/images%2Fproducts%2Fno-image.jpg?alt=media&token=a339aad7-e9c6-4f64-adba-78f66c33cda8';
  } else {
    return productImage;
  }
}

export const parseDate = (stringDate) => {
  const date = new Date(Date.parse(stringDate));
  const datestring = date.getDate()  + " " + MONTH_NAMES[date.getMonth()] + " " + date.getFullYear()
  return datestring;
}

export const parseDateTime = (stringDate) => {
  const date = new Date(Date.parse(stringDate));
  const datestring = date.getDate()  + " " + MONTH_NAMES[date.getMonth()] + " " + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes()
  return datestring;
}

export const booleanConvert = (boolean) => {
  if(boolean === true) {
      return 'Yes';
  } else {
      return 'No';
  }
}

export const getFormattedLocationPlace = (place) => {

  const name = place.name;
  const vicinity = place.vicinity;
  const country = place.country;

  return getFormattedLocation(name, vicinity, country);
}


export const getFormattedLocation = (name, vicinity, country) => {

  if(!name) {
    return '';
  }

  let locationToReturn = name;
  if(vicinity && name !== vicinity && vicinity !== 'Unknown Place') {
    locationToReturn = locationToReturn + ', ' + vicinity;
  }
  if(country && vicinity !== country && !vicinity.includes(country) && country !== 'Unknown Place') {
    locationToReturn = locationToReturn + ', ' + country;
  }

  return locationToReturn;
}

export const uuid = () => {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export const unique = (a) => {
  var seen = {};
  return a.filter(function(item) {
    return seen.hasOwnProperty(item) ? false : (seen[item] = true);
  });
}

export const createKey = (value) => {
  let key = value.toLowerCase();
  key = key.trim();
  key = key.replaceAll(" ", "-");
  key = key.replaceAll("/", "-");
  return key;
}

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const capitalizeAllWords = (str) => {
  const caps = str.split(' ').map(capitalize).join(' ');
  return caps;
}
