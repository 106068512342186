import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Container } from "react-bootstrap";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './components/app/App.css'
import reportWebVitals from './reportWebVitals';
import App from './components/app/App';
import Auth from "./components/auth/Auth";
import Upload from "./components/upload/Upload";
import Photo from "./components/photo/Photo";
import Explore from "./components/explore/Explore";
import Header from "./components/nav/Header";
import Nav from "./components/nav/Nav";
import Footer from "./components/nav/Footer";
import Map from "./components/map/Map";
import Trips from "./components/trips/Trips";
import CreateTrip from "./components/trips/CreateTrip";

ReactDOM.render(
    <BrowserRouter>
      <Container>
          <Header />
          <Nav />
          <Switch>
              <Route exact path='/' component={App} />
              <Route exact path='/auth' component={Auth} />
              <Route exact path='/upload' component={Upload} />
              <Route exact path='/explore' component={Explore} />
              <Route exact path='/explore/:type/:id' component={Explore} />
              <Route exact path='/map' component={Map} />
              <Route exact path='/trips' component={Trips} />
              <Route exact path='/create' component={CreateTrip} />
              <Route exact path='/trips/:id' component={Trips} />
              <Route exact path='/edit/:id' component={Upload} />
              <Route exact path='/photo/:id' component={Photo} />
          </Switch>
          <Footer />
      </Container>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
