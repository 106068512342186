import React, { Component } from 'react';
import './App.css';
import firebase from '../util/Firebase';
import Display from "../display/Display";

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      images: [],
      imagesMap: {}
    };

    this.imageRef = firebase.firestore().collection('images').limit(50);
    this.imageUnsub = null;
  }

  componentDidMount() {
    this.imageUnsub = this.imageRef.orderBy("createdTimestamp", "desc").onSnapshot(this.onImagesUpdate);
  }

  componentWillUnmount() {
    this.imageUnsub = this.imageUnsub();
  }

  onImagesUpdate = (querySnapshot) => {
    const images = [];
    const imagesMap = {};

    querySnapshot.forEach((doc) => {
      const { createdTimestamp, description, fileName, thumbnailURL,
        fileURL, location, owner, title, trip, placeName, placeVicinity, placeCountry } = doc.data();
      const dataToStore = { key: doc.id, createdTimestamp, description, fileName, thumbnailURL,
        fileURL, location, owner, title, trip, placeName, placeVicinity, placeCountry };
      images.push(dataToStore);
      imagesMap[doc.id] = dataToStore;
    });

    this.setState({
      images,
      imagesMap
    });
  }

  render() {

    return (
      <div className={"mt-2"}>
        <Display images={this.state.images}
                 imagesMap={this.state.imagesMap} />
      </div>
    );
  }
}

export default App;